.banner {
    height: 110px;
    margin-top:22px;
    padding-top:30px;
    border-bottom: 1px solid #c4268c;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
}

.text {
    font-size: 45px;
    font-weight: 800;
    background: white;
    margin-top: 15px;
    margin-left: 15px;
}

.after {
    content: '';
    width: 100%;
    height: 4px;
    background: -webkit-linear-gradient(0deg, #0D9DDB 0%, #00C18C 100%);
    background: linear-gradient(90deg, #0D9DDB 0%, #00C18C 100%);
  }