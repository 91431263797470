.search {
    padding-top: 65px;
    display: inline-block;
    width: 70%;
    font-size: 1.6rem;
    vertical-align: top;
}

.search__header {
    font-size: 1.2rem;
    font-weight: 800;
    padding-bottom:5px;
    font-family: AvenirNext,sans-serif,Arial,Helvetica;
}

.search__separator {
    border-bottom: .17rem solid black;
    margin-bottom: 15px;
    width: 97%
}

.search__span {
    font-size: 1.4rem;
    font-family: "Open Sans";
    display: inline-block;
    width: 230px;
}

.search__span--header {
    font-weight:600;
}

.search__span input {
    width:200px;
}