html {
    font-size: 62.5%;
}

body {
    font-family: AvenirNext,sans-serif,Arial,Helvetica;
    font-size: 1.6rem;
}

.centerContent {
    max-width: 1100px;
    margin: 0 auto;
    @media (max-width: 1150px) {
        margin-left: 20px;
    }
}

.error {
    color: red;
}

.absolute {
    position: absolute;
}

.hide-mobile {
    @media (max-width: 720px) {
        display: none;
    }
}

.single-column-hide {
    @media (max-width: 920px) {
        display: none;
    }
}

.single-column-show {
    display: none;
}
@media screen and (max-width: 920px) {
    .single-column-show  { display: block; }
}


button {
    padding: 15px;
    background-color: $button-color;
    border: none;
    border-bottom: solid 2px #C2C2C2;
    cursor: pointer;
    font-size: 1.2rem;
    font-weight: 600;
}

button:hover {
    background-color: $button-hover;
}

button:disabled {
    cursor: default;
}

input {
    margin-top: 3px;
    border: solid 2px $light-grey;
    height: 30px;
}

a {
    color: #216aa4;
}

a:visited { 
    text-decoration-color:rgb(240, 243, 247);
    text-decoration-line:none;
    text-decoration-style:solid;
}

input:disabled {
    background: #DDDDDD;
    color: #AAAAAA;
}

select:disabled {
    background: #DDDDDD;
    color: #AAAAAA;
}