.questions {
    padding-top: 25px;
    padding-left: 35px;
    display:inline-block;
    width:23%;
    font-family: "Open Sans";
    vertical-align: top;
    min-width: 230px;
}

.questions__info {
    width: 230px;
    padding: $m-size;
    background-color: $light-grey;
    font-size: 1.3rem;
    font-weight: 600;
}

.questions__info--header {
    font-family: AvenirNext-Bold,sans-serif,Arial,Helvetica;
    font-size: 1.8rem;
    font-weight: 600;
    padding-bottom: 15px;
}

.questions__info--links {
    font-weight: normal;
    font-size: 1.3rem;
    line-height: 2.5rem;
}

.questions__info--separator {
    content: '';
    width: 84px;
    border-bottom: 0.3rem solid black;
    margin-top: 5px;
    margin-bottom: 20px;
}