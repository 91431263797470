.invoice {
    font-family: "Open Sans";
    font-size: 1.5rem;
}

.invoice__link {
    font-size: 1.4rem;
}

.invoice__payButton {
    margin-top: 20px;
    background: #fedf00;
}

.invoice__payButton:hover {
    background: #fff200;
}

.invoice__form--amounts {
    display: inline-block;
}

.invoice__form--corrector {
    margin-bottom: -4px !important;
}

.invoice__form--Radio {
    vertical-align: bottom;
    margin-bottom: 2px;
    margin-right: 4px;
}

.invoice__pdfIcon {
    margin-left:20px;
    margin-bottom: -5px;
    margin-right: 10px;
    display: inline-block;
    height: 25px;
    width: 25px;
}