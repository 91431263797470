/**
* Shows how you can use CSS to style your Element's container.
* These classes are added to your Stripe Element by default.
* You can override these classNames by using the options passed
* to the CardElement component.
* https://stripe.com/docs/js/elements_object/create_element?type=card#elements_create-options-classes
*/

.checkoutLabel {
    font-size: 1.2rem;
    font-weight: 800;
    padding-bottom:5px;
}

.checkout__element {
    margin-top:5px;
    width:400px;
}

.cover {
  opacity:.5;
  position:relative;
  top:-42px; left:0px;
  display:block;
  background: #B9B9B9;
  width: 426px;
  height: 40px;
  margin-bottom: -40px;

}

.cover_hidden {
  display: none;
}

.checkout__button {
    background: #428bca;
    color:white;
    font-size: 1.8rem;
    width: 429px;
}

.checkout__button:disabled {
  background: #4dca42;
  color:white;
  font-size: 1.8rem;
  width: 429px;
}

.checkout__button:disabled:hover {
  background: #4dca42;
}

.checkout__button:hover {
    background: #3276b1;
}

.checkout__span {
  width: 440px;
  padding-bottom: 20px;
}

.checkout__span--input {
  height: 40px;
  padding: 10px 12px;
  width: 428px;
}

.checkout__select {
  height: 40px;
  padding: 10px 12px;
  width: 428px;
  background: white;
  border: 2px solid #EBEBEB;
  border-style: none;
  outline: 2px solid #EBEBEB; outline-offset:-1px;
}

.checkout__select:focus {
  outline: -webkit-focus-ring-color auto 5px;
  outline-color: -webkit-focus-ring-color;
  outline-style: auto;
  outline-width: 5px;
}

.countryNotPicked {
  color: #CACACA;
}

.countryPicked {
  color:black;
}

.StripeElement {
  height: 20px;
  padding: 10px 12px;
  width: 100%;
  color: #32325d;
  border: 2px solid #EBEBEB;
}

.StripeElement--focus {
  outline: -webkit-focus-ring-color auto 5px;
  outline-color: -webkit-focus-ring-color;
  outline-style: auto;
  outline-width: 5px;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}