$off-black: #20222b;
$off-white: #a5afd7;
$dark-blue: #333745;
$blue: #3c4251;
$light-blue: #464b5e;
$purple: #8357c5;
$light-grey: #EBEBEB;
$dark-grey: #BCBCBC;

$button-color: $light-grey;
$button-hover: $dark-grey;
$button-shadow: #C2C2C2;

//Spacing
$s-size: 1.2rem;
$m-size: 1.6rem;
$l-size: 3.2rem;
$xl-size: 4.8rem;

$footer-height: 175px;