/*Added these styles to make footer work*/

footer {
    margin-top: -$footer-height;
	width: 100%;
	text-align: -webkit-center;
	text-align: center;
	background-color: #2A2C30;
	flex: 0 0 auto;
  	display: flex;
}

.mask {
	height: 296px;
	width: 1440px;
	background-color: #2A2C30;
}

.footer_child {
	height: 36px;
	background-color: rgba(65, 64, 66, 0.2);
	bottom: 0;
	margin: 0 auto;
	max-width: 1441px;
}

.footer_links {
	height: 19px;
	width: 191px;
	color: #F0F3F7;
	font-size: 14px;
	font-weight: bold;
	letter-spacing: 0.1px;
	line-height: 19px;
	word-break: break-word;
	font-family: AvenirNext-Bold, Arial;
}

.footer_bottom_links {
	height: 19px;
	color: #F0F3F7;
	font-family: AvenirNext-Bold, "Open Sans", Arial;
	font-size: 14px;
	letter-spacing: 0.1px;
	line-height: 19px;
}

.footer_line {
	height: 1;
    width: 50%;
    border: none;
	border-bottom: 1.5px solid #F0F3F7;
	margin: 0;
	text-align: left;
}

.footerlinks, .footerlinks:visited, .footerlinks:focus, .footerlinks:hover, .footerlinks:active {
	color: #F0F3F7;
    font-family: AvenirNext-Regular, "Open Sans", Arial;
    font-size: 14px;
    letter-spacing: 0.1px;
    line-height: 19px;
    text-decoration: none;
    background: transparent;
}

.footerwrap {
	display: flex;
	text-align: -moz-center;
    text-align: -webkit-center;
    text-align: center;
}

.tdStyle {
    text-align: left !important;
    width: 33.33%;
    font-size: 25px;
    height: 28.13px;
    line-height: 1.1em;
}

/**The styles below were lifted from the feature app css**/
.tableStyle {
	width: 100%;
	max-width: 1160px;
	margin: 0 auto;
}

.innerTableStyle  {
	width: 100%;

}

.flexColumnStyle {
	flex: 1 0 auto; 
	height: 36px;
	background-color: transparent
}
.altLinkStyle {
	font-Size: 25px;
	line-Height: 1.1em;
}