.allowance__currentAmount {
    padding-bottom: 10px;
}

.allowance__creditAmount{
    padding-bottom: 40px;
}

.allowance__question {
    font-weight: 600;
    color: #216aa4;
}