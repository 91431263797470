.headerLinkStyle {
    font-size: 25px;
    line-height: 1.1em;
    margin-left: 20px;
 }

/**Styles lifted from Feature app**/

@import url("https://fonts.googleapis.com/css?family=Open+Sans");

.top-navigation {	
	height: 20px;
}

.background {
	height: 80px;
	background-color: #262626;
	box-shadow: 0 2px 4px 0 rgba(0,0,0,0.1);
}

.grid {	
	height: 60px;	
}

.main-nav-logo {
	float: left;
	width: 28%;
	height: 70px;
	text-align: right;
}

.main-nav-menu {
	width: 67%;
	float: right;
	height: 60px;
}

.main-nav-menu a:visited { 
	text-decoration-color:black;
	text-decoration-line:none;
	text-decoration-style:solid;
}

.main-nav-menu>ul>li {
    padding-top: 16px;
    padding-bottom: 40px;
    padding-right: 30px;
    float: left
}

.main-nav-menu>ul>li:nth-child(1) {
    padding-left: 20px;
    float: left;
}

.main-nav-menu>ul>li:nth-child(7) {
    float: right;
    padding-right: 20px;
}

.label {
	height: 20px;
	width: 46px;
	color: #FFFFFF;
	font-family: "Open Sans";
	font-size: 14px;
	font-weight: 600;
	letter-spacing: 0.1px;
	line-height: 20px;
}

.list-inline{padding-left:0;list-style:none}.list-inline-item{display:inline-block}.list-inline-item:not(:last-child){margin-right:.5rem}