.example {
    padding-top:10px;
    margin-left:40px;
    margin-right:40px;
    font-size: 1.4rem;
}

.example__header {
    display: flex;
    justify-content: space-between;
    font-size: 1.8rem;
}

.example h2 {
    margin: 15px 30px 5px 0px;
    font-size: 21px;
}

.checkout__button {
    background: #428bca;
    color:white;
    font-size: 1.8rem;
    width: 200px;
    height: 42px;
    padding-top: 10px;
    font-weight:400;
}